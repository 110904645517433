import { useState, useCallback, useEffect } from 'react'

export function useLocalStorage<T>(key: string, defaultData: T): [data: T, setData: (newData: T) => void] {
    const [data, setData] = useState<T>(defaultData);

    const updateData = useCallback(
        (newData: T) => {
            localStorage.setItem(key, JSON.stringify(newData));
            setData(newData)
        },
        [key],
    )

    useEffect(() => {
        const seralizedData = localStorage.getItem(key);
        if (!seralizedData) {
            return;
        }
        const deserlizedData = JSON.parse(seralizedData) as T;
        setData(deserlizedData);
    }, [key])

    return [data, updateData];
}