export function getData(pnr: string) {
    const url = `https://api.lysa-test.se/test/bankid/login?personnummer=${pnr}`;
    return fetch(url, {
        credentials: `include`,
    })
        .then(() => {
            throw new Error(`Invalid pnr`);
        })
        .catch((error) => {
            if (error.message === `Failed to fetch`) {
                return Promise.resolve();
            }
            throw error;
        });
}
