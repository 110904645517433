import { getData } from "./getData";
import { SwedishTin } from "./SwedishTin";

export function login(pnr: string, previousItems: string[], setPreviousItems: (newData: string[]) => void, setAlertHidden: (hidden: boolean)=>void) {
    const tin = new SwedishTin(pnr);
    if (!tin.isValid) {
        setAlertHidden(false);
        return;
    }
    getData(tin.getNormalizedTin())
        .then(() => {
            if (previousItems.includes(pnr) === false) {
                setPreviousItems([...previousItems, pnr]);
            }
            window.location.href = `https://app.lysa-test.se/se/`;
        })
        .catch((error) => {
            if (error.message === `Invalid pnr`) {
                setAlertHidden(false);
            }
        });
}
