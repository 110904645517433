export abstract class Tin {
    protected valid: boolean;

    constructor(protected tin: string) {
        this.valid = this.validate(tin);
    }

    isValid() {
        return this.valid;
    }

    getTin() {
        return this.tin;
    }

    protected abstract validate(tin: string): boolean;
}
