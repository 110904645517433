import { FiX } from "react-icons/fi";

// export function alertUser(message: string) {
//   const alertDiv = document.createElement(`div`);
//   alertDiv.classList.add(`alert`);

//   const p = document.createElement(`p`);
//   p.textContent = message;
//   alertDiv.appendChild(p);

//   const closeButton = document.createElement(`button`);
//   closeButton.classList.add(`close-button`);
//   const icon = document.createElement(`i`);
//   icon.setAttribute(`data-feather`, `x`);
//   closeButton.appendChild(icon);
//   alertDiv.appendChild(closeButton);

//   const body = document.querySelector(`body`);
//   body?.appendChild(alertDiv);
//   window.feather.replace();
// }

// export function cleanupAlerts() {
//   const alerts = document.querySelectorAll(`.alert`);
//   alerts.forEach((alert) => {
//     alert.remove();
//   });
// }
interface Props {
  message: string;
  hidden: boolean;
  setHidden: (hidden: boolean) => void;
}

export function AlertUser({ message, hidden, setHidden }: Props) {
  if (hidden === false) {
    return (
      <div className="alert">
        <p>{message}</p>
        <button
          className="close-button"
          onClick={(event) => {
            setHidden(true);
          }}
        >
          <FiX size={20} />
        </button>
      </div>
    );
  } else {
    return <div></div>;
  }
}
