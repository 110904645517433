import React, { useState } from "react";
import { login } from "./Login";
import { useLocalStorage } from "./useLocalStorage";
import { PreviousList } from "./PreviousList";
import { AlertUser } from "./AlertUser";

function App() {
  const [input, setInput] = useState("");
  const [previousItems, setPreviousItems] = useLocalStorage<string[]>(
    "values",
    []
  );
  const alertMessage = "Ogiltigt personnummer";
  const [alertHidden, setAlertHidden] = useState(true);
  return (
    <div>
      <div className="card1">
        <header>
          <h1>Personnummer-login</h1>
          <p>Format yyyymmddxxxx</p>
        </header>
        <input
          type="text"
          spellCheck="false"
          placeholder="yyyymmddxxxx"
          onChange={(event) => {
            setInput(event.target.value);
          }}
        />
        <button
          type="button"
          className="login-button"
          onClick={(event) => {
            login(input, previousItems, setPreviousItems, setAlertHidden);
          }}
        >
          Login
        </button>
      </div>
      <PreviousList
        items={previousItems}
        setPreviousItems={setPreviousItems}
        setAlertHidden={setAlertHidden}
      />
      <AlertUser
        message={alertMessage}
        hidden={alertHidden}
        setHidden={setAlertHidden}
      />
    </div>
  );
}

export default App;
