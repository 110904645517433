import { Tin } from "./Tin";
import { DateTime } from "luxon";

export class SwedishTin extends Tin {
    protected validate(tin: string): boolean {
        const normalizedTin = this.getNormalizedTin();
        return (
            normalizedTin.length === 12 &&
            this.isValidDate(normalizedTin) &&
            this.isValidLuhn(normalizedTin)
        );
    }

    public getNormalizedTin() {
        let normalizedPnr = this.tin.replace(/-/g, "").replace(/ /g, "");
        if (normalizedPnr.includes("+") && normalizedPnr.length < 12) {
            // People over 100
            normalizedPnr = `19${normalizedPnr}`.replace("+", "");
        } else if (normalizedPnr.includes("+")) {
            normalizedPnr = normalizedPnr.replace("+", "");
        } else if (normalizedPnr.length === 10) {
            const shortYear = new Date()
                .getFullYear()
                .toString()
                .substr(2);
            if (parseInt(normalizedPnr.substr(0, 2), 10) <= parseInt(shortYear, 10)) {
                normalizedPnr = `20${normalizedPnr}`;
            } else {
                normalizedPnr = `19${normalizedPnr}`;
            }
        }
        return normalizedPnr;
    }

    private isValidLuhn(tin: string) {
        let num: string[] = [];
        for (let i = 2; i < tin.length - 1; i++) {
            let val = parseInt(tin[i], 10);
            if (i % 2 === 0) {
                val = val * 2;
            }
            num = [...num, ...val.toString().split("")];
        }
        const controlNumber =
            (10 - (num.reduce((acc, curr) => acc + parseInt(curr, 10), 0) % 10)) % 10;
        return parseInt(tin[tin.length - 1], 10) === controlNumber;
    }

    private isValidDate(pnr: string) {
        const date = DateTime.fromFormat(pnr.substring(0, 8), "yyyyMMdd");
        return date.isValid;
    }
}
