import React from "react";
import { FiTrash2 } from "react-icons/fi";
import { login } from "./Login";

interface Props {
  items: string[];
  setPreviousItems: (newdata: string[]) => void;
  setAlertHidden: (hidden: boolean) => void;
}

export function PreviousList({
  items,
  setPreviousItems,
  setAlertHidden,
}: Props) {
  const previousList = items.map((item, index, items) => (
    <li key={item}>
      <button
        className="li-button"
        onClick={(event) => {
          login(item, items, setPreviousItems, setAlertHidden);
        }}
      >
        {item}
      </button>
      <button
        className="trash-button"
        onClick={(event) => {
          const newItems = [...items];
          newItems.splice(index, 1);
          setPreviousItems(newItems);
        }}
      >
        <FiTrash2 size={20} />
      </button>
    </li>
  ));
  if (previousList.length === 0) {
    return null;
  }

  return (
    <div className="card2">
      <header>
        <h1>Tidigare</h1>
      </header>
      <ul>{previousList}</ul>
    </div>
  );
}
